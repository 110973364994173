.card_header{
    text-align: center;
    padding:3px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid rgba(63, 62, 62, 0.2);
}

.card_center{
    padding:0px 3px 0px 3px,
}
.card_facility_tech{
    padding:0px 3px 0px 3px,
}