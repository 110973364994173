.card_header{
    text-align: center;
    padding:3px;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid rgba(63, 62, 62, 0.2);
    background-color: rgba(3, 79, 4, 0.2);
}
.card_area{

    padding:1px 3px 0px 3px,
}
.card_center{
    padding:0px 3px 0px 3px,
}
.card_contamination_lvl{
    padding:0px 3px 0px 3px,
}
.card_contamination_type{
    padding:0px 3px 0px 3px,
}