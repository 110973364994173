.sidebar_right_inner {
    margin: 2px;
    display: flex;
    justify-content:flex-start;
    flex-direction: column;
  .header{
    text-align: center;
    background-color: rgb(3, 171, 3);//same color for all list headers
    padding:7px;
    width: 95%;
    margin-top: 2px;
    color: white;
    font-weight: 600;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid rgba(63, 62, 62, 0.3);
  }
  .right_side_button{
    margin: 2px 2px 2px 2px;
    //align-self: flex-end;
    padding: 7px 0px;
    color:white;
    background-color: rgb(3,171,3);
    border-radius:5px;
    font-weight: 600;
    width: 45%;
    align-self: flex-end;
  } 
  .right_side_button_con{
    margin: 2px 5px 2px 2px;
    //align-self: flex-end;
    padding: 7px 0px;
    color:white;
    background-color: rgb(5, 91, 5);
    border-radius:5px;
    font-weight: 600;
    width: 22%;
    cursor: pointer;
    align-self: flex-end;
  }
  .right_side_button_con_ex{
    margin: 2px 5px 2px 2px;
    text-decoration: none;
    cursor: pointer;
    padding: 7px 10px;
    color:white;
    background-color: rgba(5, 91, 5, 0.7);
    border-radius:5px;
    font-size: 12px;
    font-weight: 600;
    width: 33%;
    align-self: flex-end;
  }
  .right_side_button_con_ex_ex{
    margin: 2px 5px 2px 2px;
    text-decoration: none;
    cursor: pointer;
    padding: 7px 10px;
    color:white;
    background-color: rgba(5, 91, 5, 0.7);
    border-radius:5px;
    font-size: 12px;
    font-weight: 600;
    width: 30%;
    align-self: flex-end;
  }
  hr{
    width: 100%;               
    color:rgba(63, 62, 62, 0.2);
    background-color: rgba(63, 62, 62, 0.1);
    height: 0.01px;
  }
  

}
// .swal2-popup {
//   background: rgba(63, 62, 62, 0.3) !important;
//   //border: 2.5px solid rgba(252, 252, 252, 0.5) !important;
//   backdrop-filter: blur(10px);
//   border-radius: 10px;
//   box-shadow: 1px 1px 10px 0px rgba(252, 252, 252, 0.5);
//   .swal2-title{
//     display: flex;
//     color:whitesmoke;
//     align-self: baseline;
//     border-bottom: 0.5px solid rgba(252, 252, 252, 0.5);
//     border-radius: 10px 10px 0px 0px;
    
//     //background: rgba(92, 138, 96, 0.1) !important;
//   }
//   .swal2-radio{
//     background: rgba(63, 62, 62, 00) !important;
//     font-size: 30px;
//     transform: scale(1.1); 
  
//   }
// }

