.multicriteria_outer {

    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 5px 5px 5px;
    box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
    border-radius: 5px;

    .multicriteria_title {
        margin: 5px 5px 5px 5px;
        font-weight: 600;
        padding: 10px;
        border-radius: 5px 5px 0px 0px;
        text-align: center;
        font-size: 20px;
        color: white;
        background-color: rgb(8, 212, 79);
    }

    .multicriteria_main {

        display: flex;
        margin: 5px 5px 5px 5px;
        justify-content: space-around;

        .signiHeader {
            display: flex;
            flex-direction: column;
            width: 50%;
            box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
            border-radius: 5px;

            .general_trial {
                display: flex;
                margin: 5px;
                justify-content: space-between;

                .multicriteria_significance {
                    padding: 5px;
                    display: flex;
                    width: 50%;
                    margin: 5px;
                    justify-content: space-between;

                    .multicriteria_kpis {
                        display: flex;
                        flex-direction: column;


                        .economic_label {
                            background-color: rgba(8, 212, 79, 0.3);
                            transition: 0.5s;
                            transform: scale(1.05);
                            margin-top: 2px;
                        }


                        .environmental_label {
                            background-color: rgba(8, 212, 79, 0.3);
                            transition: 0.5s;
                            transform: scale(1.05);
                            margin-top: 2px;
                        }

                        .social_label {
                            background-color: rgba(8, 212, 79, 0.3);
                            transition: 0.5s;
                            transform: scale(1.05);
                            margin-top: 2px;
                        }

                        .no_label {
                            margin-top: 2px;
                            height: 20px;
                            margin-right: 2px;
                        }

                    }

                    .multicriteria_significance_factor {
                        display: flex;
                        flex-direction: column;

                        //margin-left:50%;
                        input {
                            margin-top: 2px;
                            height: 15px;
                        }
                    }


                }

                .kpi_explanation {
                    background-image: linear-gradient(130deg, rgb(251, 227, 227), rgb(255, 255, 255), rgb(251, 227, 227));
                    width: 47%;
                    margin-right: 0%;
                    box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
                    border-radius: 5px;
                    transition: 0.5s;
                    
                }
                .kpi_none {
                    width: 47%;
                    background-color: red;
                    opacity: 0;
                }
            }
            }

            .multicriteria_weight_factors {
                padding: 5px;
                display: flex;
                flex-direction: column;
                width: 45%;
                box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
                border-radius: 5px;
                justify-content: flex-start;

                .multicriteria_title {
                    font-weight: 600;
                    border-radius: 5px;
                    text-align: center;
                    font-size: 22px;
                    color: grey;
                }

                .outer_weight_factors {
                    display: flex;
                    justify-content: space-around;

                    .weight_factors {

                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;

                        label {
                            margin-top: 10px;
                            height: 20px;
                            margin-right: 2px;
                        }

                        .environmental_wf,
                        .economic_wf{
                            padding: 0px 15px 5px 5px;
                            display: flex;
                            align-items: flex-end;
                        }
                        .social_wf {
                            padding: 0px 15px 5px 5px;
                            display: flex;
                            align-items: flex-end;
                            color: grey;
                        }

                        .economic_wf:hover {
                            border-radius: 5px;
                            box-shadow: 1px 1px 5px 1px rgba(8, 212, 79, 0.83);
                            transform: scale(1.1);
                            transition: 0.75s;
                        }

                        .environmental_wf:hover {
                            border-radius: 5px;
                            box-shadow: 1px 1px 5px 1px rgba(8, 212, 79, 0.83);
                            transform: scale(1.1);
                            transition: 0.75s;
                        }

                        .social_wf:hover {
                            border-radius: 5px;
                            box-shadow: 1px 1px 5px 1px rgba(8, 212, 79, 0.83);
                            transform: scale(1.1);
                            transition: 0.75s;
                        }

                    }

                    .weight_factors_input {
                        display: flex;
                        flex-direction: column;

                        input {
                            width: 40px;
                            font-size: 18px;
                            text-align: center;
                            margin-top: 18px;
                            height: 15px;
                        }
                    }
                    
                }
                .weight_factors_info{
                    display: flex;
                    border-radius: 5px;
                    background-color: white;
                    box-shadow: 1px 1px 5px 1px rgba(175, 175, 175, 0.83);
                    width:90%;
                    height: 100%;
                    margin:30px;
                    .inner_info{
                        padding:5px;
                        text-align: justify;
                        text-justify: inter-word;
                    }
                    
                }

            }
        }

        .button_multi {
            margin: 1% 2% 1% 0%;
            //align-self: flex-end;
            padding: 7px 0px;
            color: white;
            background-color: rgb(5, 91, 5);
            border-radius: 5px;
            font-weight: 600;
            width: 7%;
            align-self: flex-end;
            cursor: pointer;
        }

        .multicriteria_results {


            height: 400px;
            display: flex;
            justify-content: space-evenly;

            .multicriteria_datagrid {
                width: 50%;
                margin: 0px 0px 5px 0px;
                box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
                border-radius: 5px;

            }

            .multicriteria_barchart {
                width: 45%;
                margin: 0px 0px 5px 0px;
                box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }