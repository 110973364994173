.Main{
 
  display: flex;
  flex:12;
  height: 100vh;
  font-family:Verdana, Geneva, Tahoma, sans-serif;


  .sidebar{
    background-color: white;
    flex:2.05;
    z-index: 12;
    box-shadow: 1px 0px 1px 0px grey;
  }
  .sidebar-right{
    background-color: white;
    flex:3;
    z-index: 1;
    height: 100vh;
    box-shadow: -1px 0px 1px 0px grey;
  }
  .sidebar-right-closed
  {
    display: none;
  }
  .main_view{
   flex:8.5;
   overflow: hidden;
   
  }
  .closebutton
  {
    font-size: 15px;
    font-weight:600;
    color: grey;
    cursor: pointer;
    justify-self: flex-end;
    writing-mode:vertical-lr;
    padding: 5px;
    border-color: rgba(0,0,0,0.5);
    background-color:rgba(252, 137, 35, 0.4) ;
  }
  
}
  
