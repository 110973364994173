.details_view {
    display: flex;
    height: 115vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    flex: 12;
  
    .sidebar {
      flex: 2.05;
      background-color: white;
      box-shadow: 1px 0px 1px 0px grey;
    }
    .central_details_view {
      flex:10.5;
      
      .multicriteria_hidden{
        display: flex;
        justify-content: center;
        margin: 5px 5px 5px 5px;
        box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
        border-radius: 5px;
        padding:5px;
        align-items: center;
      }
      .multicriteria_hidden:hover{
        transform: scale(1.01);        
      }
    }
    .custombottom {
      transform: translateX(-105px) !important;
      background-color: red;
      color:red;
      display: none;
    }
  }
  