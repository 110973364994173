.sidebar_list {
  display: flex;
  margin-left: -20px;
  flex-direction: column;
  hr {
    margin-left: 37px;
    width: 80%;               
    color:rgba(63, 62, 62, 0.2);
    background-color: rgba(63, 62, 62, 0.1);
    height: 0.01px;
    margin-top: -10px;
  }

  ul {
    list-style-type: none;
  }
  
  .navigation_ul
  {
    color:rgb(128, 128, 128);
    font-weight: 600;
  }
  .view_ul{
    font-size: small;
    margin-top: -10px;
    li{
      cursor: pointer;
      margin:10px;
      padding:10px 10px 10px 10px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      font-size: 15px;
      font-weight: 600;
      color:grey;
    }
  }
  .steps_ul{
    color:grey;
    margin-top: -10px;
    font-weight: 700;
  }
  .steps_sidebar
  {   
    margin-top: -10px;
    font-size: small;
    li {
      color:grey;
      cursor: pointer;
      border-radius: 3px;
      margin:10px;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      img {
        margin-left: 5px;
        width: 22px;
        height: 20px;
        //background-color: aqua;
      }
    }
  }
}
