.user_view {
  display: flex;
  height: 100vh;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  flex: 12;

  .sidebar {
    flex: 2.05;
    background-color: white;
    box-shadow: 1px 0px 1px 0px grey;
  }
  .central_user_view {
    text-align: center;
    flex:10.5;
    h5{
        font-size: larger;
        font-weight: 600;
        text-align: center;
        padding: 15px 0px 15px 0px;
        background-color: rgba(255, 179, 102, 0.4) ;
        border: 1px solid rgba(141, 139, 137, 0.4);
    }
  }

}
