.leaflet-container {
    width: 100%;
    height: 100vh;
    z-index: 0;
  }
.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}
.leaflet-routing-container .leaflet-bar .leaflet-routing-collapsible .leaflet-control {
  display: none;
  color: red;
}



.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
